<template>
  <tr class="tr item-tr">
    <td class="td item-td td-poster">
      <NewsCardPoster :news="news" @news="newsSearchClose" />
    </td>
    <td class="td item-td __textIgnore">
      <div class="item-tr-content" @click="showNew">
        <div class="__title">{{ news.titleNews }}</div>
        <div class="__desc" v-html="getShortDesc(news.textNews)"></div>
        <div class="__date">{{ date_locale(news.date) }}</div>
      </div>
    </td>
  </tr>
</template>

<script>
  export default {
    props: ["news"],
    components: {
      NewsCardPoster: () => import("./NewsCardPoster.vue"),
    },
    methods: {
      showNew() {
        this.$store.state.news.NewsVisor = this.news;
        this.$router.push({ query: { news: this.news._id } }).catch(() => {});
        this.newsSearchClose();
      },
      newsSearchClose: function() {
        this.$store.state.news.NewsSearch = null;
      },
      getShortDesc: function(text, limit) {
        this.$screen.width > 768 ? (limit = 550) : (limit = 100);
        return this.$global.getShort(text, limit);
      },
    },
  };
</script>

<style lang="scss">
  .item-tr {
    &-name {
      display: flex;
      align-items: center;
    }
    .td {
      vertical-align: top;
      padding: $mpadding $mpadding/2;
    }
    .__date {
      font-size: 80%;
      margin: auto 0 0 0;
      [txt] {
        @include Ellipsis();
      }
    }
    .__title {
      font-family: $sec_font;
      font-weight: normal;
      font-size: 120%;
    }
    .__desc {
      font-size: 90%;
      margin: $mpadding/2 0 $mpadding/2 0;
    }
    .td-date {
      max-width: 20%;
    }
    .news-card-poster {
      width: 156px;
      height: 156px;
      min-height: 156px;
      border-radius: $mpadding/2;
    }
    &:hover {
      .news-card-poster-overlay {
        opacity: 1;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      min-height: 156px;
      cursor: pointer;
    }
    @include screen_tablet() {
      .news-card-poster {
        width: 480px;
        height: 250px;
        min-height: 250px;
      }
      .__desc {
        font-size: 100%;
        text-align: justify;
      }
      &-content {
        min-height: 256px;
      }
    }
  }
</style>
